import React from 'react'

import pageQuery from '../../../pageQuery'
import Img from 'gatsby-image'

import { Section, Circle } from './style'

const PaymentsWhatsApp = () => {
  const data = pageQuery()
  return (
    <Section className='mt-4 d-flex align-items-center'>

      <div className='row'>
        <div className='col-12'>
          <Img fluid={data.googlePayLogo.fluid} className='whats-image mb-3' />
          <h2 className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 mt-4 fw-400 mb-4'>
            É a carteira digital exclusiva para quem tem smartphone Android.
          </h2>
          <p className='fs-16 lh-19  text-grayscale--500 fw-700 mb-3'>
            Como cadastrar seu cartão Inter no Google Pay
          </p>
          <div className='row d-flex justify-content-between'>
            <div className='col-md-8 col-lg-5 col-xl-5'>
              <h3 className='fs-16 lh-19 text-grayscale--500 fw-700 mb-4'>
                Pelo Super App do Inter
              </h3>
              <div className='d-flex mb-4'>
                <Circle>
                  1
                </Circle>

                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                  Acesse o app do Inter;
                </p>
              </div>
              <div className='d-flex  mb-4'>
                <Circle>
                  2
                </Circle>

                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                  Abra as configurações do seu cartão e vá em “Google Pay”;
                </p>
              </div>
              <div className='d-flex  mb-4'>
                <Circle>
                  3
                </Circle>

                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                  Escaneie ou insira manualmente os dados do seu cartão e siga as instruções para finalizar.
                </p>
              </div>
            </div>
            <div className='col-md-8 col-lg-5 col-xl-5'>
              <h3 className='fs-16 lh-19 text-grayscale--500 fw-700 mb-4'>
                Pelo app Google Pay
              </h3>
              <div className='d-flex mb-4'>
                <Circle>
                  1
                </Circle>

                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                  Acesse o app Google Pay;
                </p>
              </div>
              <div className='d-flex  mb-4'>
                <Circle>
                  2
                </Circle>

                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                  Toque em “Adicionar uma forma de pagamento”;
                </p>
              </div>
              <div className='d-flex  mb-4'>
                <Circle>
                  3
                </Circle>

                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                  Selecione a opção “Cartão de débito ou crédito”;
                </p>
              </div>
              <div className='d-flex  mb-4'>
                <Circle>
                  4
                </Circle>

                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                  Escaneie ou insira manualmente os dados do seu cartão e siga as instruções para finalizar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PaymentsWhatsApp
