import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }


img {
  width: 303px;
  height: 314px;

  @media (min-width: ${breakpoints.md}){
    width: 361px;
    height: 361px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 361px;
    height: 361px;
  }


  @media (min-width: ${breakpoints.xl}){
    width: 565px;
    height: 526px;
  }
}


`

export const Circle = styled.div`
    background: #ff7a00;
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-right: 24px;


`
