import React, { useState, useLayoutEffect } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Modal } from 'src/components/Modal'

import useWidth from 'src/hooks/window/useWidth'

import useDomReady from 'src/hooks/window/useDomReady'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import { Section, Button, Link } from './style'
import PaymentApproachQrCode from '../../assets/images/qrcode-carteirasdigitais.png'

const WIDTH_MD = 768

const PaymentApproach = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const modalOpen = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block">Escaneie o QR Code,</span> acesse o app'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí, é só baixar o app do Inter, abrir a sua conta digital e começar a indicar.'
        qrCode={PaymentApproachQrCode}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])
  return (
    <Section className='py-5 d-flex align-items-center'>
      {modalOpen}
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12'>
            <h2 className='fs-28 lh-30 fs-lg-40 lh-xl-50 text-grayscale--500 fw-500 mb-5 text-center'>
              Pagamento por aproximação é seguro?
            </h2>
          </div>
          <div className='col-12'>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--400 text-center'>
              Aqui no Inter, sua segurança também é nossa prioridade. Por isso, os pagamentos são feitos de forma rápida, prática e muito segura. Além da tecnologia NFC ter altos padrões de segurança, você tem total controle para ativar e desativar essa funcionalidade do seu cartão a qualquer momento.
            </p>
          </div>
        </div>
        <div className='d-flex justify-content-center mt-5'>
          {
            isMobile ? (
              <Link
                href='https://inter-co.onelink.me/Qyu7/cmsa2znn'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    element_action: 'click button',
                    element_name: 'Faça pagamentos digitais com o Inter',
                    section_name: 'Pagamento por aproximação é seguro?',
                    redirect_url: 'https://inter-co.onelink.me/Qyu7/cmsa2znn',
                  })
                }}
              >
                Faça pagamentos digitais com o Inter
              </Link>

            ) : (
              <Button onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Faça pagamentos digitais com o Inter',
                  section_name: 'Pagamento por aproximação é seguro?',
                })
              }}
              >
                Faça pagamentos digitais com o Inter
              </Button>
            )
          }
        </div>
      </div>
    </Section>
  )
}

export default PaymentApproach
