import React from 'react'
import { widths } from 'src/styles/breakpoints'
import ImageWebp from 'src/components/ImageWebp'

import { Button, Section } from './style'
import useWidth from 'src/hooks/window/useWidth'

const GooglePayIsInter = () => {
  const width = useWidth(300)
  return (
    <Section className='pt-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-28 lh-30 fs-lg-40 lh-lg-40 text-grayscale--500 fw-500 mb-4'>
              Google Pay é no Inter
            </h2>
            <p className='fs-18 lh-21 text-grayscale--500 fw-400'>
              Suas compras por aproximação com a Carteira do Google valem pontos extras no Inter Loop!
            </p>
            <p className='fs-18 lh-21 text-grayscale--500 fw-400'>Cadastre seu cartão Inter e aproveite.</p>
            {
              width < widths.md && (
                <>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/carteiras-digitais-dobra-6/image.webp'
                    altDescription=''
                    arrayNumbers={[ 300, 400, 400, 400, 400 ]}
                  />
                </>
              )
            }
            <Button
              href='https://intergo.app/b21cecdd'
              target='_blank'
              rel='noreferrer'
            >
              Cadastrar na Carteira do Google
            </Button>
            <p className='fs-14 lh-18 text-grayscale--400 fw-400 mt-4 termo'>*Condição válida para clientes selecionados.</p>
          </div>
          {
            width >= widths.md && (
              <div className='col-12 col-md-6'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/carteiras-digitais-dobra-6/image.webp'
                  altDescription=''
                  arrayNumbers={[ 300, 300, 400, 450, 500 ]}
                />
              </div>
            )
          }
        </div>
      </div>
    </Section>
  )
}

export default GooglePayIsInter
