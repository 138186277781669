import React from 'react'
import Breadcrumb from 'src/components/Breadcrumb'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Section } from './style'
import ImageWebp from 'src/components/ImageWebp'
import { Link } from 'gatsby'

const Hero = () => {
  return (
    <Section className='pt-3 pb-5 pt-lg-0 d-flex align-items-lg-center'>
      <div className='container pb-lg-5'>
        <div className='row'>
          <div className='col-12 d-none d-md-block'>
            <Breadcrumb
              sectionName='Carteiras Digitais e pagamento por aproximação é Inter'
              text='Carteiras Digitais'
              link='/pra-voce/cartoes/carteiras-digitais'
              type='pra você' product='Cartão de Débito e Crédito'
              styles='breadcrumb mb-md-4'
            />
          </div>
        </div>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-6'>
            <h1>
              Carteiras Digitais e pagamento por aproximação é Inter
            </h1>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400'>
              Faça pagamentos por aproximação e utilize seu cartão Inter nas principais Carteiras Digitais para simplificar seu dia a dia.
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400'>
              E o melhor: acumule pontos para trocar por benefícios no Inter Loop!
            </p>
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-first order-md-last mb-4 mb-md-0 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/hero-carteiras-web/image.webp'
              altDescription='Pagamento por aproximação com o celular, utilizando o cartão Inter nas carteiras digitais'
            />
          </div>
        </div>
        <div className='row my-5'>
          <div className='col-12'>
            <Link
              to='#simplifiquePagamento'
              elementName='Conheça mais as carteiras digitais'
              sectionName=' Carteiras Digitais e pagamento por aproximação é Inter'
            >
              <div className='text-center icon'>
                <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-orange--extra fw-600 mb-0'>
                  Conheça mais as carteiras digitais
                </p>
                <OrangeDsIcon size='MD' color='#ff7a00' icon='chevron-down' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
