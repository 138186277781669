import React from 'react'

import { Section, Content } from './style'

import GooglePay from './_google-pay/_index'
import SamgungPay from './_samsung-pay/_index'
import PaymentsWhatsApp from './_payments-whatsapp/_index'
import AppleContante from './_apple/_index'
import PayPal from './_paypal/_index'

const IphoneExclusiveDigitalWallet = () => {
  return (
    <Section className='pb-5 d-flex align-items-center'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <Content className='line-3'>
              <PaymentsWhatsApp />
            </Content>
          </div>
          <div className='col-12 col-lg-6'>
            <Content className='line-1'>
              <AppleContante />
            </Content>
          </div>
          <div className='col-12 col-lg-6'>
            <Content className='line-1'>
              <GooglePay />
            </Content>
          </div>
          <div className='col-12 col-lg-6'>
            <Content className='line-2'>
              <SamgungPay />
            </Content>
          </div>
          <div className='col-12 col-lg-6'>
            <Content className='line-2'>
              <PayPal />
            </Content>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default IphoneExclusiveDigitalWallet
