import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'

export const Section = styled.section`
 h2 {
  font-family: 'Citrina', sans-serif;
 }
.termo {
  font-style: italic;
}
`

export const Button = styled.a`
  background:  ${orange.extra};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${white};
  margin-top: 32px;
  display: flex ;
  align-items: center;
  justify-content: center;

  &:focus, &:hover{
    outline: none;
    color: ${white};
  }

`
