import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

import Img from 'gatsby-image'
import pageQuery from '../../pageQuery'
import OpenVideo from 'src/components/OpenVideo'
import ImageWebp from 'src/components/ImageWebp'

const WhatIsDigitalWallet = () => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='py-5 d-flex align-items-center justify-content-between'>
      <div className='container'>
        <div className='row d-flex justify-content-end'>
          <div className='col-12 col-md-6 d-none d-md-block relative'>
            <div className='play-image'>
              <OpenVideo
                link='https://www.youtube.com/embed/0ZzEJD90UxQ'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: '',
                    section_name: 'O que é uma carteira digital?',
                  })
                }}
              >
                <Img alt='O que é uma carteira digital? - Vídeo' fluid={data.WhatIsDigitalWalletPlay.fluid} />
              </OpenVideo>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <h2 className='fs-28 fs-lg-40 lh-32 lh-lg-50 text-white fw-500 mb-5'>O que é uma carteira digital? </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-white'>É um meio de pagamento que reúne as informações dos seus cartões para fazer compras por aproximação em lojas físicas (com a tecnologia NFC, MST) ou em lojas online. </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5 d-md-none mt-5'>
            <OpenVideo
              link='https://www.youtube.com/embed/0ZzEJD90UxQ'
              onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_04',
                element_action: 'click button',
                element_name: '',
                section_name: 'O que é uma carteira digital?',
              })
              }}
            >
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cartoes-video-play/image.webp'
                altDescription='O que é uma carteira digital? - Vídeo'
              />
            </OpenVideo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhatIsDigitalWallet
