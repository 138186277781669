import React from 'react'

import { Section } from './style'

const SimplifyPayment = () => {
  return (
    <Section
      className='py-4 d-flex align-items-center justify-content-between'
      id='simplifiquePagamento'
      role='img'
      aria-label='Foto de uma pessoa sorridente enquanto realiza um pagamento por aproximação encostando seu celular próximo de um terminal de pagamentos que outra pessoa está segurando.'
    >
      <div className='container'>
        <div className='row d-flex justify-content-end'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-md-28 fs-lg-40 lh-lg-50 text-grayscale--500 fw-400 mb-4'>
              Simplifique com o pagamento por aproximação pelo celular
            </h2>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 mb-0'>
              Para começar a usar, seu celular precisa ter a tecnologia NFC. Depois, basta cadastrar seu cartão de crédito ou débito no Apple Pay, Google Pay ou Samsung Pay, e aproximar a parte de trás do seu celular em uma maquininha que tenha essa funcionalidade. Simples assim!
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SimplifyPayment
