import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import { Circle, Section } from './style'

const HowToPay = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-lg-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-md-28 fs-lg-40 lh-lg-50 text-grayscale--500 fw-400 mb-4'>
              Como pagar por aproximação com o cartão Inter?
            </h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--400 mb-5'>
              Primeiro, você precisa ativar o pagamento por aproximação no Super App do Inter. Confira o passo a passo:
            </p>
            <div className='d-flex align-items-center mb-3'>
              <Circle>
                1
              </Circle>
              <p className='fs-14 lh-18 text-grayscale--400 mb-0'>Acesse o app e vá em “Cartões” na página inicial;</p>
            </div>
            <hr />
            <div className='d-flex align-items-center mb-3'>
              <Circle>
                2
              </Circle>
              <p className='fs-14 lh-18 text-grayscale--400 mb-0'>Toque em “Configurações”; </p>
            </div>
            <hr />
            <div className='d-flex align-items-center mb-3'>
              <Circle>
                3
              </Circle>
              <p className='fs-14 lh-18 text-grayscale--400 mb-0'>Escolha a opção "Cartão"; </p>
            </div>
            <hr />
            <div className='d-flex align-items-center mb-3'>
              <Circle>
                4
              </Circle>
              <p className='fs-14 lh-18 text-grayscale--400 mb-0'>Ative a chave “Compras por aproximação (contactless)”;</p>
            </div>
            <hr />
            <p className='fs-14 lh-18 text-grayscale--400 mb-4'>Pronto! O pagamento digital por aproximação está ativado. Agora, é só começar a usar! </p>
          </div>
          <div className='col-12 col-lg-6 mt-3 mt-md-0 d-none d-lg-flex justify-content-end'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/carteiras-dobra3/image.webp'
              altDescription='Mão de uma pessoa segurando um cartão contactless Inter laranja próximo de uma maquininha de cartão para efetivar o pagamento por aproximação.'
              arrayNumbers={[ 1, 2, 3, 4 ]}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HowToPay
